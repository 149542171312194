<template>
    <div>
        <div v-if="notFound">Not found</div>
        <div v-if="base64">
            <PDFView :src.sync="base64" :fileName="$route.params.name"></PDFView>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import {PDFView} from '@gabrielbu/vue-pdf-viewer';

export default {
    name: 'Download',
    components: {
        PDFView
    },
    data() {
        return {
            notFound: false,
            open: false,
            base64: null
        }
    },
    mounted() {
        this.$root.$children[0].loading = false

        this.open = this.$route.meta.open || false
        let name = encodeURI(this.$route.params.name);
        name = name.replace(/#/g, '%23');

        axios.get(window.apiUrl + '/download/' + this.$route.params.type + '/' + name)
            .then(response => {
                if (this.open && response.data.type == 'application/pdf') {
                    this.base64 = "data:application/pdf;base64, " + response.data.content
                } else {
                    const blob = new Blob([this.s2ab(atob(response.data.content))], {type: response.data.type});
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.name = this.$route.params.name;
                    if (!this.open) {
                        link.download = this.$route.params.name;
                    }
                    link.click();
                    if (!this.open) {
                        setTimeout(() => {
                            window.close();
                        }, 1000)
                    }
                }
            })
            .catch(errors => {
                if (errors.response.data.code == 1700) {
                    this.notFound = true
                }
            })
    },
    methods: {
        s2ab(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        },
        handleDownload() {
            const blob = new Blob([this.s2ab(atob(this.base64))], {type: 'application/pdf'});
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = this.$route.params.name;
            link.click();
        }
    }
}
</script>